export const components = {
    "my-debug": {
        init: function () {
            console.log("Hello, World!");
        },
        tick: function () {
            const rig = document.querySelector("#rig");
            const player = document.querySelector("#player");
            console.log("rig", rig.object3D.rotation, "player", player.object3D.rotation);
        },
    },
    "custom-layout": {
        schema: {
            type: { type: "string", default: "space-between" },
            coordinate: { type: "string", default: "x" },
        },
        init: function () {
            this.updateLayout = this.updateLayout.bind(this);
            this.el.addEventListener("child-attached", this.updateLayout);
            this.el.addEventListener("child-detached", this.updateLayout);
            this.el.addEventListener("change-visible", this.updateLayout);
            if (this.data.type === "line" ||
                this.data.type === "space-between" ||
                this.data.type === "space-around") {
                this.updateLayout();
            }
        },
        updateLayout: function () {
            const children = this.el.children;
            const type = this.data.type;
            const coordinate = this.data.coordinate;
            let totalWidth;
            let totalWidthElement = this.el;
            while (!totalWidth) {
                totalWidth = totalWidthElement.getAttribute("width");
                totalWidthElement = totalWidthElement.parentElement;
            }
            let totalHeight;
            let totalHeightElement = this.el;
            while (!totalHeight) {
                totalHeight = totalHeightElement.getAttribute("height");
                totalHeightElement = totalHeightElement.parentElement;
            }
            let totalChildren = 0;
            const visibleChildren = [];
            for (let i = 0; i < children.length; i++) {
                const isVisible = children[i].getAttribute("visible");
                if (isVisible) {
                    totalChildren++;
                    visibleChildren.push(children[i]);
                }
            }
            if (type === "space-between") {
                for (let i = 0; i < totalChildren; i++) {
                    const child = visibleChildren[i];
                    const position = child.getAttribute("position");
                    let xCoordinate = position.x;
                    let yCoordinate = position.y;
                    const zCoordinate = position.z;
                    if (coordinate === "x") {
                        xCoordinate =
                            (i - (totalChildren - 1) / 2) * (totalWidth / totalChildren);
                    }
                    else if (coordinate === "y") {
                        yCoordinate =
                            -(i - (totalChildren - 1) / 2) * (totalHeight / totalChildren);
                    }
                    child.setAttribute("position", `${xCoordinate} ${yCoordinate} ${zCoordinate}`);
                }
            }
            else if (type === "space-around") {
                const spacing = totalWidth / (totalChildren + 1);
                for (let i = 0; i < totalChildren; i++) {
                    const child = visibleChildren[i];
                    const position = child.getAttribute("position");
                    let xCoordinate = position.x;
                    let yCoordinate = position.y;
                    const zCoordinate = position.z;
                    if (coordinate === "x") {
                        xCoordinate = (i + 1) * spacing - totalWidth / 2;
                    }
                    else if (coordinate === "y") {
                        yCoordinate = -(i + 1) * spacing + totalHeight / 2;
                    }
                    child.setAttribute("position", `${xCoordinate} ${yCoordinate} ${zCoordinate}`);
                }
            }
        },
        remove: function () {
            this.el.removeEventListener("child-attached", this.updateLayout);
            this.el.removeEventListener("child-detached", this.updateLayout);
        },
    },
    "carousel-2d": {
        schema: {
            index: {
                type: "number",
                default: 0,
            },
        },
    },
    overlay: {
        dependencies: ["material"],
        init: function () {
            const applyOverlay = (object3D) => {
                var _a, _b;
                object3D.renderOrder = 100;
                if (object3D.material) {
                    object3D.material.depthTest = false;
                    object3D.material.transparent = true;
                }
                const textMaterial = (_b = (_a = object3D.el.components) === null || _a === void 0 ? void 0 : _a.text) === null || _b === void 0 ? void 0 : _b.material;
                if (textMaterial) {
                    textMaterial.depthTest = false;
                    textMaterial.transparent = true;
                }
                object3D.children.forEach((child) => applyOverlay(child));
            };
            this.el.sceneEl.renderer.sortObjects = true;
            applyOverlay(this.el.object3D);
        },
    },
    "fit-texture": {
        dependencies: ["geometry", "material"],
        schema: {
            type: "boolean",
            default: true,
        },
        /**
         * Called once when component is attached. Generally for initial setup.
         */
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        init: function () { },
        /**
         * Called when component is attached and when component data changes.
         * Generally modifies the entity based on the data.
         */
        update: function () {
            if (this.data === false)
                return;
            const el = this.el;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            if (self.dimensions) {
                // If texture has already been loaded, and `fit-texture` was reset.
                self.applyTransformation();
            }
            else {
                const textureLoaded = function (e) {
                    const w = e.detail.texture.image.videoWidth || e.detail.texture.image.width;
                    const h = e.detail.texture.image.videoHeight || e.detail.texture.image.height;
                    // Don't apply transformation on incomplete info
                    if (h === 0 || w === 0)
                        return;
                    // Save dimensions for later updates to `fit-texture`, see above.
                    self.dimensions = { w: w, h: h };
                    self.applyTransformation();
                };
                el.addEventListener("materialvideoloadeddata", textureLoaded);
                el.addEventListener("materialtextureloaded", textureLoaded);
            }
        },
        applyTransformation: function () {
            const el = this.el;
            const geometry = el.getAttribute("geometry");
            const widthHeightRatio = this.dimensions.h / this.dimensions.w;
            if (geometry.width != 1 && geometry.height != 1) {
                console.warn('Using `fit-texture` component on an element with both width and height. Therefore keeping width and changing height to fit the texture. If you want to manually set both width and height, set `fit-texture="false"`. ');
            }
            if (geometry.width != 1) {
                el.setAttribute("height", geometry.width * widthHeightRatio);
            }
            else if (geometry.height != 1) {
                el.setAttribute("width", geometry.height / widthHeightRatio);
            }
            else {
                // Neither width nor height is set.
                const tempWidth = 1.0;
                el.setAttribute("width", "" + tempWidth);
                el.setAttribute("height", tempWidth * widthHeightRatio);
            }
        },
        /**
         * Called when a component is removed (e.g., via removeAttribute).
         * Generally undoes all modifications to the entity.
         */
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        remove: function () { },
        /**
         * Called on each scene tick.
         */
        // tick: function (t) { },
        /**
         * Called when entity pauses.
         * Use to stop or remove any dynamic or background behavior such as events.
         */
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        pause: function () { },
        /**
         * Called when entity resumes.
         * Use to continue or add any dynamic or background behavior such as events.
         */
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        play: function () { },
    },
};
